// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import clsx from "clsx";

import {
  Button,
  Column,
} from 'devextreme-react/data-grid';
// MUI
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReportIcon from '@mui/icons-material/Report';
import Functions from '@mui/icons-material/Functions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button as MuiButton, TextField, Modal } from '@mui/material';

// Core
import { actionsGetPostAsync } from "../../../../engine/core/tables/saga/asyncAction";
import useStyles from "../styles";

const Approve = (props: any) => {
  const { data } = props;
  const { dataStoreReload } = props;
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSendApprove = () => {
    setPending(true);
    dispatch(actionsGetPostAsync({
      url: data?.actions?.approve,
      method: 'POST',
      dataReload: dataStoreReload.callback
    }))
  };
  if (data?.actions?.approve && !data?.approved?.state) {
    return (
      <Tooltip title={t('actionsRender.approve').toString()} onClick={pending ? undefined : onSendApprove} enterTouchDelay={0}>
        <span
          className={clsx(`dx-link dx-link-icon ${classes.action}`, {
              [classes.activeAction]: data?.approved?.currentUserApproveStatus,
              [classes.disabledAction]: pending,
            }
          )}
          aria-label="approve"
        >
          <ThumbUpAltIcon fontSize="small"/>
        </span>
      </Tooltip>
    )
  }
  return null;
}

const Decline = (props: any) => {
  const { data } = props;
  const { dataStoreReload } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [comment, setComment] = useState('');
  const classes = useStyles();

  const handleOpenModal = () => {
    setComment('');
    setIsOpenModal(true);
  }

  const handleCloseModal = () => setIsOpenModal(false);

  const onSendDecline = () => {
    setPending(true);
    dispatch(actionsGetPostAsync({
      url: data?.actions?.decline,
      method: 'POST',
      dataReload: dataStoreReload.callback,
      data: comment
    }))
  };

  if (data?.actions?.decline && !data?.approved?.state) {
    return (
      <>
        <Tooltip title={t('actionsRender.decline').toString()} onClick={pending ? undefined : handleOpenModal} enterTouchDelay={0}>
          <span
            className={clsx(`dx-link dx-link-icon ${classes.action}`, {
                [classes.activeAction]: data?.approved?.currentUserApproveStatus === false,
                [classes.disabledAction]: pending,
              }
            )}
            aria-label="decline"
            >
            <ThumbDownAltIcon fontSize="small"/>
          </span>
        </Tooltip>
        <Modal
          open={isOpenModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalWrapper}>
            <h4 className={classes.modalTitle}>{t('Add your comment')}</h4>
            <TextField
              fullWidth
              id="outlined-basic"
              label={t('Comment')}
              variant="outlined"
              sx={{ marginBottom: 3 }}
              InputLabelProps={{
                style: { marginTop: 0 }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComment(event.target.value);
              }}
            />
            <MuiButton onClick={onSendDecline} disabled={!comment} variant='contained'>{t('Send')}</MuiButton>
          </div>
        </Modal>
      </>
    )
  }
  return null;
}

const RenderButton = (props: any) => {
  const { data, actionName } = props;
  const dataStoreReload = props;
  const { t } = useTranslation();
  switch (actionName) {
    case 'edit': {
      if (data?.actions?.edit) {
        return (
          <a
            className="dx-link"
            aria-label="edit"
            href={data.actions.edit}
          >
            <Tooltip title={t('actionsRender.edit').toString()} enterTouchDelay={0}>
              <i className="dx-link dx-icon-edit dx-link-icon"/>
            </Tooltip>
          </a>
        )
      }
      break
    }
    case 'delete': {
      if (data?.actions?.delete) {
        return (
          <a
            className="dx-link dx-link-delete"
            aria-label="delete"
            href={data.actions.delete}
          >
            <Tooltip title={t('actionsRender.delete').toString()} enterTouchDelay={0}>
              <i className="dx-link dx-icon-trash dx-link-icon"/>
            </Tooltip>
          </a>
        )
      }
      break
    }
    case 'show': {
      if (data?.actions?.show) {
        return (
          <a
            className="dx-link dx-link-icon"
            aria-label="show"
            href={data?.actions?.show}
          >
            <Tooltip title={t('actionsRender.show').toString()} enterTouchDelay={0}>
              <RemoveRedEyeIcon fontSize="small"/>
            </Tooltip>
          </a>
        )
      }
      break
    }
    case 'unsubscribe': {
      if (data?.actions?.unsubscribe) {
        return (
          <a
            className="dx-link dx-link-icon"
            aria-label="unsubscribe"
            href={data?.actions?.unsubscribe}>
            <Tooltip title={t('actionsRender.unsubscribe').toString()} enterTouchDelay={0}>
              <ReportIcon fontSize="small"/>
            </Tooltip>
          </a>
        )
      }
      break
    }
    case 'dayoff_edit': {
      if (data?.actions?.dayoff) {
        return (
          <a
            className="dx-link dx-link-icon"
            aria-label="show"
            href={data?.actions?.dayoff}>
            <Tooltip title={t('actionsRender.dayoff_edit').toString()} enterTouchDelay={0}>
              <Functions fontSize="small"/>
            </Tooltip>
          </a>
        )
      }
      break
    }
    case 'dayoff_balance_edit': {
      if (data?.actions?.dayoff_balance) {
        return (
          <a
            className="dx-link dx-link-icon"
            aria-label="show"
            href={data?.actions?.dayoff_balance}>
            <Tooltip title={t('actionsRender.dayoff_balance_edit').toString()} enterTouchDelay={0}>
              <CalendarMonthIcon fontSize="small"/>
            </Tooltip>
          </a>
        )
      }
      break
    }
    case 'approve': {
      return <Approve dataStoreReload={dataStoreReload} data={data}/>
    }
    case 'decline': {
      return <Decline dataStoreReload={dataStoreReload} data={data}/>
    }
  }
  return null;
}

export const ActionsRender = (actions: any, dataStoreReload: any) => {
  if (actions) {
    const { actionsList, width } = actions;
    const actionsWidth = actionsList.length * 40;

    if (actionsList?.length !== 0) {
      return (
        <Column
          type='buttons'
          width={width || (actionsWidth > 70 ? actionsWidth : 70)}
          allowReordering={false}
          allowResizing={false}
          showInColumnChooser={false}>
          {actionsList?.map((actionName: string) => {
            const onClick = (e: any) => {
              window.location.href = e.row.data.actions.edit;
              return null;
            };
            // eslint-disable-next-line react/jsx-props-no-spreading
            const renderButton = (props: any) => <RenderButton callback={dataStoreReload} actionName={actionName} {...props} />
            return <Button
              onClick={actionName === 'edit' ? onClick : undefined}
              key={actionName}
              name={actionName}
              render={renderButton}/>
          })}
        </Column>
      )
    }
  }
  return null
}
